.center-text {
  text-align: center;
  padding-bottom: 2.5%;

  h2 {
    color: #ed6663;
  }
}

.logoLarge {
  padding-top: 10%;
  padding-bottom: 2.5%;
}

.logo-image {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  opacity: .5;
}

#backButton {
  font-size: 18px;
}