.new_button {
    border: none;
    display: flex;
    padding: 12px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: #3C8599;
    color: var(--grey-grey-50, #F9FAFB);
    font-family: Space Grotesk;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.manage_button {
    border: none;
    height: 35px;
    display: flex;
    padding: 12px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: #3C8599;
    color: var(--grey-grey-50, #F9FAFB);
    font-family: Space Grotesk;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.new_icon {
    border: none;
    height: 35px;
    background-color: #3C8599;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #F9FAFB;
    margin-left: 10px;
}

.remove {
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;

}

.upload_button {
    border: none;
    height: 35px;
    display: flex;
    padding: 12px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: none;
    color: #425EE0;
    font-family: Space Grotesk;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}