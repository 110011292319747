@import "../../../Config.scss";

.sidebar {
    position: sticky;
    top: 80px;

    .section-filter {
        margin-top: 0;
        border: 0;
        -webkit-box-shadow: 4px 4px 11px 9px #ebebeb, 0 0.37px 4.42626px #ebebeb,
        0 3px 7px #ebebeb;
        box-shadow: 4px 4px 11px 9px #ebebeb, 0 0.37px 4.42626px #ebebeb,
        0 3px 7px #ebebeb;
        margin-bottom: 30px;
        padding: 25px;
        position: sticky;

        h2 {
            color: #fff;
            font-size: 20px;
            font-weight: 500;
            position: relative;
            display: inline-block;
            font-family: $font-stack;
            letter-spacing: 1px;
            text-transform: uppercase;
            min-width: 97%;
            margin: -25px 0 0 -25px;
            margin-bottom: 25px;
            vertical-align: top;

            &::after {
                content: "";
                position: absolute;
                width: 60px;
                height: 100%;
                transform: skew(-35deg);
                right: -35px;
                top: 0;
                background-color: inherit;
                z-index: 0;
            }
        }

        .filter-item-wrap {
            .input-group-text {
                border-left: 0;

                button {
                    background-color: white;
                    border: 0;

                    &:focus-visible {
                        background-color: red;
                    }

                }

                padding: 0;
            }

            .keyword-search {
                border-bottom-right-radius: 0 !important;
                border-top-right-radius: 0 !important;
                height: auto;

            }

            .PrivateSwitchBase-root-1 {
                padding: 0 9px;
            }

            .jss1 {
                padding: 0 9px;
            }

            .btn-clear-filters {
                cursor: pointer;
                padding: 5px 15px;
            }
        }
    }

    label {
        display: block;
        font-weight: 600;
        font-size: 14px;
    }

    .form-check-input {
        position: relative;
        margin-top: 0.3rem;
        margin-left: 0;
        margin-right: 0.25rem;
    }
}
