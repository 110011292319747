#upload-new-image-tab {
    .croppers-container {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        .crop-container {
            min-height: 280px;
            width: 100%;
            position: relative;

            .reactEasyCrop_CropArea {
                border: 1px solid rgba(255, 255, 255, 0.9);
                color: rgba(0, 0, 0, 0.7);
            }

            .aspect-ratio-label {
                position: absolute;
                top: -30px;
                left: 50%;
                transform: translateX(-50%);
                color: rgba(0, 0, 0, 0.7);
                font-weight: bold;
                text-align: center;
            }
        }
    }
    .controls-container {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        margin-top: 20px;
    }
    .controls {
        text-align: center;
        margin: 0 auto;
        width: 200px;
    }
    .labels {
        text-align: center;
        margin: 20px auto;
        width: 100%;
        display: flex;

        label {
            display: flex;
            align-items: center;
            margin: 0 40px;

            input[type="checkbox"] {
                margin-right: 10px;
            }

            input[type="select"] {
                margin-left: 10px;
                padding: 5px;
            }
        }
    }
}
