.main-cart-container {
    .main-cart-table {
        tbody {
            tr.error-item {
                background-color: #f8d7da !important;
            }

            td {
                vertical-align: middle;

                .qty {
                    float: right;

                    .form-control {
                        height: 36px;
                        text-align: center;
                        width: 55px;
                    }
                }

                .remove-item {
                    background-color: #fff;
                    background-image: none;
                    color: #333;
                    cursor: pointer;
                    padding: 6px 10px;
                    border-radius: 0;
                    text-decoration: none;
                    float: left;
                    transition: all 0.3s linear;
                    -moz-transition: all 0.3s linear;
                    -webkit-transition: all 0.3s linear;
                    border: 1px #ddd solid;

                    &:hover {
                        background-color: #ed6663;
                        color: #fff;
                        border: 1px #ed6663 solid;
                    }
                }
            }
        }

        tfoot {
            .controls-row {
                td {
                    border: none;
                }
            }
        }
    }
}

.checkout-success {
    font-size: 16px;
    line-height: 30px;

    .btn {
        color: #fff;
        border-radius: 4px;

        &:hover {
            color: #fff;
        }
    }
}

.payment-buttons {
    display: flex;
    justify-content: center;
    margin: 20px;
}

.continue-btn {
    display: flex;
    justify-content: center;
}

.hr-sect {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    color: rgba(0, 0, 0, 0.35);
    margin: 1em 0;
}

.hr-sect:before,
.hr-sect:after {
    content: '';
    flex-grow: 1;
    background: rgba(0, 0, 0, 0.35);
    height: 1px;
    font-size: 0;
    line-height: 0;
    margin: 0 8px;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottom-buttons {
    display: flex;
    justify-content: space-between;

    .cart-button {
        margin: 1em;
        width: auto;

        button {
            width: 225px;
        }
    }

    .paypal-card-buttons {
        width: (-webkit-fill-available);
        max-width: 50%;
        height: auto;

        .paypal-error-container {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .paypal-error-text {
            text-align: right;
            margin: 0 !important;
        }
    }
}

.proceed-button {
    float: right;
}
