@import '../../Config.scss';

.inline-field {
    label {
        float: left;
        padding: 7px;;
    }
    &__controls {
        float: left;
    }
}

.form-title {
    font-size: 22px;
    margin-bottom: 20px;
    display: inline-block;
    border-bottom: 1px #eee solid;
    padding-bottom: 10px;
    width: 100%;
    border-bottom: 1px #eaeaea solid;
    font-family: $font-stack;
    font-weight: 500;
    letter-spacing: 0.5px;
}