.carousel .carousel-inner .carousel-item img {
    margin: 0;
    max-width: 100%;
}

.homepage-container {
    width: 100%;
}


.header {
    display: flex;
    background: white;
    padding: 10px;
    width: 100%;
}

.hero-text {
    text-align: left;
    font-size: 35px;
}

.header-title {
    font-size: 35px;
}

.header-subtitle {
    font-size: 20px;
}

.cta-button {
    display: flex;
    align-items: center;
}

.join-button {
    background: #007BFF;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
}

.join-link {
    color: white;
    text-decoration: none;
}

.join-text {
    font-size: 25px;
}

.carousel-container {
    background: white;
    padding: 10px;
    display: flex;
    justify-content: center;
    margin: 0;
}

.carousel-wrapper {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    height: auto; 
}

.carousel {
    width: 100%;
    height: auto;
}

.carousel-item {
    text-align: center;
}

.carousel-item img {
    max-width: 100%;
    height: auto;
}

.challenges-container {
    background: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
}

.challenges-title {
    text-align: left;
    font-size: 25px;
}

.subsection {
    font-size: 20px;
}

.steps-container {
    background: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
}

.steps-title {
    text-align: left;
    font-size: 25px;
}

.steps-list {
    list-style-type: none;
    padding: 0;
}

.steps-list li {
    font-size: 20px;
    margin-bottom: 5px;
}

.join-button-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.black-button {
    background-color: black;
    color: white;
  }
.join-text{
    text-decoration: underline;
}