.default-image-wrap {
    text-align: center;

    .img-block {
        position: relative;
        display: inline-block;
        cursor: pointer;

        :hover+#overlay {
            display: block;
        }

        #overlay {
            display: none;
            width: 100%;
            height: 50%;
            position: absolute;
            top: 50%;
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);

            &:hover {
                display: block;
                background-color: rgba(0, 0, 0, 0.8);
            }

            #btn-upload-brandimg-wrap {
                position: absolute;
                top: 70%;
                left: 50%;
                color: #fff;
                font-weight: bold;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                font-size: 16px;
                z-index: 20;
                width: 100%;
                height: 100%;

                span {
                    padding-left: 5px;
                }
            }
        }
    }
}

.pending-img {
    filter: blur(1px) brightness(50%);
}

#img-status {
    padding: 10px;
    text-align: center;
    font-size: 18px;
    width: 100%;
}

.default-image {
    padding-top: 25px;
    background-color: #fff;
}

.manage-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.brand-text {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.current-brand-text {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}