.mini-cart{

    .dropdown-toggle:after { content: none }

    table {
        margin: 0;
    }

    .dropdown-item {
        padding-left: 15px;
        padding-right: 15px;
    }

    .mini-cart-body:hover {
        background-color: white;
    }

    .btn-checkout {
        padding: 12px 0 9px 0;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 1px!important;
        display: block;
        border: none;
        border-radius: 0;
        width: 100%;
        font-family: 'Saira Condensed', sans-serif;
    }

    .dropdown-menu {
        border: 0;
        box-shadow: 0 6px 9px 0 rgba(36,39,44,.2);
    }

    .vertical-center {
        vertical-align: middle;
    }

    .product-name {
        margin-bottom: 0;
    }

    .mini-cart-img {
        width: 60px;
    }

    .bottom-border {
        border-bottom: solid 0.5px lightgrey;
    }

    .trash:hover {
        color: #ed6663 !important;
    }
}