@import "../../../Config.scss";

.page {
    padding: 0 25px;

    &__container {
        padding: 0 25px;
    }
}

.top-slides {
    margin-top: 70px;
    height: 18vw;
}

.carousel-inner {
    border-radius: 0 !important;
}

.page-title {
    text-align: center;
    background-color: white;


    h1, h2 {
        color: black;
        font-family: $font-stack;
        font-size: 46px;
        font-weight: 400;
        text-transform: uppercase;
        padding: 0 25px;
        margin: 0;
        display: inline-block;
        margin-top: 5px;
    }
}
