.new_button {
    display: flex !important;
    padding: 12px 12px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px !important;
    background-color: #3C8599 !important;
    color: var(--grey-grey-50, #F9FAFB) !important;
    font-family: Space Grotesk !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 20px !important;
}

.new_button:hover {
    background-color: #3C8599; 
    color: var(--grey-grey-50, #F9FAFB);

}

.new_button:disabled {
    background-color: #F3F4F6 !important;
    color: #9CA3AF !important;
    cursor: not-allowed !important;
}

.customized-dialog {
    z-index: 999;
    min-width: 800px;
    position: relative; 
}

.top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px; 
}

.text-box {
    width: 300px;
}

.star {
    color: red;
}