.col-card {
    padding: 8px 16px;

    .product-card {
        border-radius: 4px;
        border: 0;
        transition: box-shadow 200ms ease-out;

        &:hover {
            -webkit-box-shadow: 4px 4px 11px 9px #ebebeb, 0 0.37px 4.42626px #ebebeb,
            0 3px 7px #ebebeb;
            box-shadow: 4px 4px 11px 9px #ebebeb, 0 0.37px 4.42626px #ebebeb,
            0 3px 7px #ebebeb;
        }
    }
}

.product-card {
    margin-bottom: 12px;
    height: 100%;
    cursor: pointer;

    .ribbon {
        width: 150px;
        height: 150px;
        overflow: hidden;
        position: absolute;
    }
    .ribbon::before,
    .ribbon::after {
        position: absolute;
        z-index: -1;
        content: '';
        display: block;
    }
    .ribbon span {
        position: absolute;
        display: block;
        width: 150%;
        padding: 5px 0;
        background-color: dodgerblue;
        color: white;
        text-transform: uppercase;
        text-align: center;
        font-size: 17px;
        right: -8px;
        top: 30px;
        transform: rotate(-45deg);
    }

    .ribbon-top-left::before {
        top: 0;
        right: 0;
    }
    .ribbon-top-left::after {
        bottom: 0;
        left: 0;
    }

    .card-body {
        padding: 0.6rem;

        .card-title {
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            margin-bottom: 0.75rem;
            font-size: 17px;
            font-weight: 400;
        }
    }

    .place-bid-wrap {
        overflow: hidden;

        .auto-bid {

            .MuiTypography-body1 {
                font-size: 0.8rem
            }
        }
        button {
            font-size: 14px;
        }
    }

    .bid-count-wrap {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

    .productButtons {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

    .current-price-wrap {
        padding: 10px 0;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        font-size: 16px;
        font-weight: 500;
    }
}

.clock-container {
    text-align: center;

    .container-fluid {
        padding: 0;
    }

    .clock {
        font-size: 20px;
        font-weight: 500;
        margin: 0 auto;
        text-align: center;

        .clock-row {

            .col {
                padding: 0;
            }
            border: 0;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;

            .text-dark {

                .tag {
                    color: #a7a7a7;
                    font-size: 0.8rem;
                    font-weight: 400;
                }
                span {
                    &:first-child {
                        font-weight: 700;
                    }
                }
            }
        }

        div {
            color: gray;
            border: 0;
            display: inline-block;
            margin: 0 1px;
            text-align: center;
            border-radius: 5px;
            position: relative;

            span {
                display: block;
            }

            .tag {
                font-size: 9px;
                padding: 0;
                margin: 0;
            }
        }
    }
}

.btn-bid {
    &:after {
        content: "Place Bid";
    }

    &.not-loggedin:hover:after {
        content: "Login";
    }

    &.acc-not-approved:hover:after {
        content: "Account approval pending";
    }

    &.acc-suspended:hover:after {
        content: "Account is suspended";
    }

    &.no-payment-method:hover:after {
        content: "Add Payment Method";
    }

    &.no-default-image:hover:after {
        content: "Upload Default Image";
    }

    &.no-address:hover:after {
        content: "Add Business Information";
    }

    &.no-email-verification:hover:after {
        content: "Verify Your Email";
    }

    border-radius: 4px;
}

.btn-add-to-cart {
    margin-bottom: 36px;
    margin-top: 20px;
    border-radius: 4px;

    &:after {
        content: "Add to Cart";
    }

    &.not-loggedin {
        &:hover {
            &:after {
                content: "Login";
            }
        }
    }

    &.acc-not-approved {
        &:hover {
            &:after {
                content: "Account approval pending";
            }
        }
    }

    &.acc-suspended {
        &:hover {
            &:after {
                content: "Account is suspended";
            }
        }
    }

    &.no-payment-method {
        &:hover {
            &:after {
                content: "Add Payment Method";
            }
        }
    }

    &.no-default-image {
        &:hover {
            &:after {
                content: "Upload Default Image";
            }
        }
    }

    &.no-address {
        &:hover {
            &:after {
                content: "Add Business Information";
            }
        }
    }

    &.no-email-verification {
        &:hover {
            &:after {
                content: "Verify Your Email";
            }
        }
    }
}