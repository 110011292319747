.image-colour-editor {
    .image-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%; /* Ensures the container takes up the full available height */

        img {
            max-width: 100%;
            height: auto;
            display: block; /* Ensures the image doesn't have extra space around it */
            margin: 0 auto; /* Centers the image horizontally */
        }

        .loading-spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            
            
            .spinner-border{
                border-radius: 50% !important;
            }
        }

        .image-loading {
            opacity: 0.5;
        }
    }

    .controls {
        margin-top: 20px;
    }
}
