.product-detail-wrap {
    .MuiTypography-body1 {
        font-size: 0.9rem;
        font-family: inherit;
    }
    .info {
        .row {
            .price {
                font-weight: bold;
            }
            margin-bottom: 20px;

            .list-group-item {
                border: none;
            }
        }
        .auction-details{
            padding: 0.25rem;
        }
        .current-price {
            display: block;
            padding: 5px 0;
            text-align: center;
        }
    }
}