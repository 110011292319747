.order-details-container {
    .order-status {
        border: 2px solid #ccc;
        border-radius: 3px;
        display: inline-block;
        text-transform: uppercase;
        vertical-align: middle;
        padding: 5px 18px;
        font-size: 16px;
    }

    .order-date {
        display: inline-block;
        font-size: 17px;
        float: right;
        vertical-align: top;
        margin-top: 6px;
        padding: 5px 18px;
    }

    .table {
        margin: 30px 0;
    }

    .brand-image {
        border: 2px solid black;
    }

    .order-items-table {
        thead {
            th {
                border-bottom: 1px solid #ccc;
            }
        }

        tfoot {
            background-color: #f5f5f5;
        }
    }

    .brand-image-td {
        display: flex;
        flex-wrap: wrap;
    }

    .image-container {
        flex-basis: 50%;
        padding: 10px;
    }

}