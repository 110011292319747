@import '../../../Config.scss';

.footer {
    border-radius: 0 !important;
    overflow: auto;
    color: white;
    background-color:dark;

    a {
        color: #d5d5d5;
        transition: 0.5s;
    }

    a:hover {
        color: gray;
        transition: 0.5s;
    }

    .topic-text {
        color: #d5d5d5;
        transition: 0.5s;
    }

    .links {
        margin-right: 0;
        margin-left: auto;
    }

    .links a {
        color: white;
        transition: 0.5s;
    }

    .links a:hover {
        color: gray;
        transition: 0.5s;
    }

    .col-no-padding {
        padding: 0 !important;
        max-width: 20px !important;
    }

    .footer-text {
        color: #d5d5d5;
        text-align: justify;
        transition: 0.5s;
        padding: 0.75rem;
    }

    .footer-container {
        max-width: 100% !important;
        padding: 20px 5vw 0 5vw;
    }

    .max-content {
        width: max-content;
    }

    .links-col {
        margin-left: auto;
        margin-right: auto;
    }

    .contact-col {
        margin-left: auto;
    }

    .mid-container {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .f-copy {
        width: auto;
        border-radius: 0!important;
        font-size: 18px;
        padding-bottom: 10px;
        background-color: dark;
        padding-top: 10px;
        text-align: center;
        overflow: auto;
    }
}