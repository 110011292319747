@import "~bootstrap/scss/bootstrap";

@import url("https://fonts.googleapis.com/css?family=Open+Sans:400italic,600italic,700italic,400,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Teko:300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Saira+Condensed:300,400,500,600,700,800");

@import "Config.scss";

.container {
  // @media (min-width: 1350px) {
  //     max-width: 1320px;
  // }
}

body {
  font-family: $font-stack;
  background: #f5f5f5;
  line-height: 1.6em;

  button,
  input,
  .form-control,
  div {
    border-radius: 4px !important;
  }
}

.page-heading{
    border-radius: 0px !important;
}

.page-title{
    border-radius: 0px !important;
}

label, input, select {
    // font-family: $font-stack-2;
}

a {
    color: #333;
    text-decoration: none;
    &:focus {
        outline: none;
    }
    &:hover {
        color: #ed6663;
        text-decoration: none;
    }
}
a.terms-and-conditions
{
    color: #007bff;
    text-decoration: none;
    &:focus {
        outline: none;
    }
    &:hover {
        color: #ed6663;
        text-decoration: none;
    }
}
input {
    border-radius: 0px !important;
}
.btn, .btn-sm {
    border-radius: 0px;
}

.btn-primary:active {
    background-color: #05468b !important;
}
.btn-success:active{
    background-color: #053811 !important;
}

.cursor-pointer {
    cursor: pointer;
}
.no-right-padding {
    padding-bottom: 15px;
}
.no-left-padding {
    padding-left: 0 !important;
    margin-left: 0 !important;
}
.show {
    display: block !important;
}

.notification-container {
    top: 60px!important;
    font-size: 18px;
    font-family: $font-stack-2;
}

//Form validation
.srv-validation-message {
    font-size: 14px;
}

//Material-UI
.MuiTypography-body1 {
    font-family: inherit !important;
}

.ag-header-cell-label {
    justify-content: center;
}

.requiredStar {
    color: #ed6663;
}

.side-bar-list-item {
    span:hover{
        color: #ed6663;
    }
}

.center-content {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.font-14 {
  font-size: 14px;
}

.accordion-heading {
    background-color: whitesmoke !important;
}

.accordion-heading-text {
    font-size: 18px !important;
}

.webstore-accordion {
    box-shadow: 0 3px 3px 0 rgba(36,39,44,.05)!important;
}

label {
  margin-bottom: 0.5rem;
}

.form-group {
  margin-bottom: 1rem;
}

.list-group-item {
  padding: .75rem 1.25rem;
}

.table td {
  padding: 0.75rem;
}

.table > :not(:first-child) {
  border-top: 0;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
  padding: 0.75rem;
}

.btn-success {
  background-color: #28a745;
  border-color: #28a745;
}

button.close {
  background-color: transparent;
  border: 0;
}

.invert-color {
    opacity: 0.65;
}