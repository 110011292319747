@import '../../../Config.scss';

header {
    width: 100%;
    z-index: 1000;
    background: #fff;
    color:#fff;
    box-shadow: 0 6px 9px 0 rgba(36,39,44,.2);
    position: fixed;
    top: 0;
}

.header {
    .header-banner {
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: 40px;
            content: '';
            background-color: #e05c0b;
        }
        overflow: hidden;
        font-family: 'Saira Condensed', sans-serif;
        background: #23292e;
        height: 40px;

        .call-us {
            color: #fff;
            text-align: right;
            font-size: 17px;
            font-weight: 400;
            padding: 9px 5px;
            letter-spacing: 0.5px;
        }
        .offers {
            color: #fff;
            text-align: center;
            font-size: 17px;
            font-weight: 400;
            padding: 9px 5px;
            letter-spacing: 0.5px;
        }
    }
}

.outer-nav-container {
    max-width: 100% !important;
    padding: 0 5vw 0 5vw;
}

.nav-container {
    padding: 0!important;
    width: 100%;

    .nav-collapse {
        flex-grow: 0;
        width: auto;
    }

    .filter-item-wrap {
        margin-left: auto;
        margin-right: auto;
    }
}

.search-group {

    input {
        border-color: whitesmoke;
        border-radius: 20px !important;
        border-right: 0;
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
        background-color: whitesmoke;
    }

    input:focus, button:focus {
        outline: none;
        box-shadow: none;
        background-color: whitesmoke;
        border-color: whitesmoke;
    }

    button, button:active, button:enabled, button:hover {
        border-top-right-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
        border-color: whitesmoke !important;
        border-left: 0;
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
        background-color: whitesmoke !important;
        color: gray !important;
        outline: none !important;
        box-shadow: none !important;
    }

    button:hover, button:active {
        color: #ed6663!important;
    }
}

.nav-drop-down {
    color: #333;
}

.nav-drop-down:hover {
    color: #ed6663;
}

.nav-link {
    color: #333 !important;
    font-family: $font-stack;
    font-size: 16px;
    text-transform: uppercase;
    padding-left: 10px!important;
    padding-right: 10px !important;
}

.nav-link:hover {
    color: #ed6663 !important;
}

.nav-item {
    padding-left: 10px!important;
    padding-right: 10px !important;
}

.mini-cart {
    margin-right: 20px;
    .basket {
        cursor:pointer;
        a {
            color: #121212;
            font-size: 20px;;
            &:hover {
                color: #121212;
            }
            span {
                background: #ed6663;
                display: inline-block;
                color: #fff;
                width: 20px;
                border-radius: 20px;
                font-size: 11px;
                height: 20px;
                line-height: 19px;
                text-align: center;
                vertical-align: top;
                right: -1px;
                font-family: $font-stack;
                font-weight: 500;
            }
        }
    }
}