.block-ui-container {
    .block-ui-message {
        font-size: 16px;

        .loading-indicator {
            .loading-bullet {
                font-size: 3em;
                color: #ed6663
            }
        }
    }
}