@import "../../Config.scss";

.main-container {
    .container-fluid {
        padding: 30px 5vw 30px 5vw;
        margin-top: 60px;
        .page-container-div {
            margin-right: auto;
            margin-left: auto;
            @media (max-width: 375px) {
                padding: 0;
                .page {
                    padding: 0;
                }
            }
            @media (min-width:768px) {
                padding: 0;
                .page {
                    padding: 0;
                }
            }
        }
    }
}

.footer {
    position: sticky;
    top: 100%;
    width: 100%;
    background-color: #292a2d;
    color: white;
}

.footer-content {
    border-radius: 0 !important;
    margin-top: 0.5rem;
    padding: 0.5rem;
    background-color: rgb(108, 117, 125);
    position: center;
    bottom: 0;
    left: 0;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.footer-bar {
    border-radius: 0 !important;
    margin-top: 0;
    padding: 0.5rem;
    background-color: rgb(237, 102, 99);
    position: center;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.footer-text {
    color: #d5d5d5;
    text-align: justify;
}